<template>
  
    <div id="muestrasClientes">





        <div class="FModal" v-if="showModal">

            <div class="FB1">
                
                <div class="dflx dvmuestras">
                    <div>
                        <p class="mt2">Parámetros Solicitados</p>
                    </div>
                </div>
                <div class="dflx">
                    <div class="estudios">
                    
                        <div  class=" est_item">
                            <p v-for="(est, key) in estudios" :key="key" @click="delestudio(key)"> {{est.name}}<span v-if="key < (estudios.length-1)">, </span> </p>
                            
                        </div>
                        
                    </div>
                
                </div>
                <div class="dflx">
                    <div class="btnelimp">
                        <p v-if="estudios.length >0" class="eliminarult" >Click al estudio para eliminar.</p>
                    </div>
                </div>

                <div class="btnAdd" v-on:click="saveResult">
                    <img src="../../assets/i_mas.png" alt="">
                    <p class="">  Agregar</p>
                </div>

            </div>

            <div class="FB2">
                <div class="buscador2 ">

                    <input v-model="search_value3" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
                    <div class="icon_buscar">
                        <img  src="../../assets/Search.png" alt="icono buscar">
                    </div>

                </div>

                <p  class="eliminarult2 " >*Buscar perfil por nombre.</p>
                <p class="cliheader clht">Parametros Encontrados</p>
                <div class="tablacli">
                    
                    <div v-if="prmData != 'No se encontraron coincidencias.'">
                        <div class="cliitem" v-for="(prm, key) in prmData" :key="key">
                            <p class="cliname" @click="pushValue(prm)">{{prm.name}}</p>
                        </div>
                    </div>
                    <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>
                </div>
                <p class="cliheader  mtp0">Perfiles Encontrados</p>

                <div class="tablacli">
                    
                    <div v-if="perfiles != 'No se encontraron coincidencias.'">
                        <div class="cliitem" v-for="(pfl, key) in perfiles" :key="key">
                            <p class="cliname" @click="pushValues(pfl.params_profile , pfl.id )">{{pfl.name }}</p>
                        </div>
                    </div>

                    <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>

                </div>
            </div>

            <div class="FB3" v-on:click="showModal=false">
                <img src="../../assets/i_eliminar.png">
            </div>

        </div>
        


        




       
    
        <div class="info_cliente">
            <p class="client_name"> <span v-if="muestra.servicio.id_sucursal !=null && muestra.servicio.sucursal.name"> {{muestra.servicio.sucursal.name}}</span> <span v-else> {{muestra.servicio.cliente.name}}</span></p>
             <p class="t1p">{{muestra.servicio.clave_LAASON}}</p>
              <p class="t2p">No. muestra {{muestra.num}}</p>
             <p class="t2p">Datos de la muestra</p>
            <form @submit.prevent="viewModal= true">
            <div class="dflx">
            <p class="lbl1">Tipo de Muestra</p> 
            <input v-model="muestra.tipo" type="text" class="inp i1" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />  
            <p class="lbl2">Muestreada por</p>
              <input v-model="muestra.toma" type="text" class="inp i2" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />  
          
            <p class="lbl3">Lugar de muestreo</p>
             <input v-model="muestra.extra.direc_toma" type="text" class="inp i3" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />  

           
         </div>
        
           <div class="dflx">
             <p class="lbl4">Descripción</p> 
             <input v-model="muestra.descrp" type="text" class="inp i4" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />  
           </div>
           <div class="dflx">
            <p class="lbl4">Apariencia</p> 
             <input v-model="muestra.extra.apar" type="text" class="inp i4" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />  
         </div>
          <div class="dflx">
            <p class="lbl77">Observaciones</p> 
            <input v-model="muestra.extra.obv" type="text" class="inp i77" :disabled="identity.role != 'legrafica' && identity.role != 'spadmin' && identity.role != 'admin'" required />
         </div>

        

          <button type="submit" v-if="identity.role == 'legrafica' || identity.role == 'spadmin' || identity.role == 'admin'" class="btnEditar">Editar</button>
        </form>

        <div class="dflx mtp1">
            <p class="lbl5">Fecha de impresion</p> 
            <p class="inp i5">{{convertdate(muestra.servicio.fecha)}} {{muestra.servicio.hr}} hrs</p>   
            
            
         </div>

         <p class="t2p tt2">Resultados de la muestra</p>
        <div class="dflx">
            <p class="lbl6">Fecha de inicio</p> 
            <p class="inp i6"> <span v-if="muestra.fecha_i != null"> {{convertdate(muestra.fecha_i)}}</span> </p>   
             <p class="lbl7">Fecha de finalización</p> 
            <p class="inp i7"><span v-if="muestra.fecha_f != null"> {{convertdate(muestra.fecha_f)}}</span></p>   
            
         </div>

           <div class="dflx">
            <p class="lbl8">Naturaleza</p> 
            <p class="inp i8">{{muestra.tipo}}</p>   
           
            
         </div>
         
         
        </div> <!-- info cliente --->

   

        <div>
           
        

        <div class="tbl_muestracliente">
            <div class="tbl_header">
                <div class="tblh hdr1">
                  <p>PARÁMETROS</p>
                </div>
                 <div class="tblh hdr2">
                  <p>RESULTADOS</p>
                </div>
                 <div class="tblh hdr3">
                  <p>LIMITE DE CUANTIFICACIÓN</p>
                </div>
                 <div class="tblh hdr4">
                  <p>REFERENCIA</p>
                </div>
                
               
               
            </div>
            
            <div tbl_content>
                <div > 
                    <div class="tbl_items" v-for="(res, key) in arrayMuestras.resultados" :key="key">
                        <div  class="tbl_item itt1">
                               <p>{{res.pname}} </p>
                        </div>
                         <div  class="tbl_item itt2">
                               <p> <span v-if="res.resultado != null">{{res.resultado + ' ' +res.uni}}</span> </p>
                        </div>
                         <div  class="tbl_item itt3">
                               <p>{{res.lc}}</p>
                        </div>
                         <div  class="tbl_item itt4">
                               <p>{{res.ref}}</p>
                        </div>
                       
                        <div v-if="identity.role != 'cliente'">
                            <div v-if="waitdelete" class="itt8" v-on:click="eliminar(res.id), waitdelete=false">
                                <img src="../../assets/eliminar_icon.png">
                            </div>
                            <div v-else class="itt8">
                                <img src="../../assets/eliminar_icon.png">
                            </div>
                        </div>
                       
                    </div>
                    
                </div>  
          
            </div>
            <div class="btnPlus" v-if="identity.role != 'cliente'" v-on:click="showModal=true">
                <img src="../../assets/i_mas.png">
            </div>
        </div>

        </div>

       <!--
        <p class="t2p tt2">Analistas</p>
        <div>
           
        

        <div class="tbl_analistas">
            <div class="tbl_header">
                <div class="tblh hdr5">
                  <p>NOMBRE</p>
                </div>
                 <div class="tblh hdr6">
                  <p>SIGLAS</p>
                </div>
                 <div class="tblh hdr7">
                  <p>ESTATUS</p>
                </div>
                
                
               
               
            </div>
            
            <div tbl_content>
              
                <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.analista.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                               <p> {{muestra.analistas.analista.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>ANALISTA</p>
                        </div>
                    </div>
                </div>  

                 <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.liberacion.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                               <p> {{muestra.analistas.liberacion.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>LIBERACIÓN</p>
                        </div>
                    </div>
                </div>  


                 <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.superviso.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                              <p> {{muestra.analistas.superviso.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>SUPERVISÓ</p>
                        </div>
                    </div>
                </div>  


          
            </div>
        </div>

        </div> -->
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        <div class="dflx">
                             <p @click="back()" class="btnRegresar3">Regresar</p>
                           <!--  <p v-if="identity.role != 'cliente' && identity.role != 'sucursal'" class="btnsFoo btf1"> Descargar PDF</p> -->
                            
                        </div>

                      

      
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        

        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_delete">
            <p @click="viewModal = false" class="close_modal"> &#215;</p>
            <h3 class="title_modal">Editar Información</h3>
            <p class="message_modal">¿Estás seguro que deseas editar esta muestra?</p>
            <div class="modal_btns dflx">
                <p @click="viewModal = false" class="cancelar">cancelar</p> <p  @click="editarDatos()" class="eliminar">Editar</p>
            </div>
          </div>
        </div>
        

    </div>
</template>
<script>

import dayjs from 'dayjs'
import {mapActions} from 'vuex';
export default {
    name: 'muestrasClientes',
    
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           variable:'< 1.1 NMP/100 ml',
            search_value3:'',
           viewModal:false,
            estudios:[],
            arrayMuestras:[],
            showModal: false,
            waitdelete: true,
            viewParams:false,
           
           
       }
   },
    async created (){
        this.getAllInfoPrf('profile')
        this.getAllInfoPrm('parametros');
        let response = await  this.getIdentity()
        
        let data = {
            "id": response.result.sub,
            "option": 'client'
        }
       this.getInfoByIdCli(data)
   },
    computed:{
        prmData() {
            const array = this.$store.getters["parametros/data"];
            if(!Array.isArray(array)) return 'No se encontraron coincidencias.';
            
            if(this.arrayMuestras.servicio.forma == 2) return array;
            else return array.filter(item => item.type == this.arrayMuestras.servicio.forma);
        },
        perfiles() {
            const data = this.$store.getters["profile/data"];
            
            let arr =[]

            if(data !='No se encontraron coincidencias.' && data !='error'){
                if( this.search_value3 !=''){
                        const coincide = (item ) => this.includesItem(this.search_value3,item.name) && item.params_profile != 'No se encontraron coincidencias.'
                        arr = data.filter(coincide)
                }else{
                    const coincide = (item ) =>  item.params_profile != 'No se encontraron coincidencias.'
                        arr = data.filter(coincide)
                }
            }else{
                arr = data
            }

            return arr

            //search_value3
        },
        cliente(){
            return this.$store.getters["solserv/getAdded"]
        },
        historyOption(){
            return this.$store.getters["solserv/getHistoryOption"]
        },

        muestra(){
            this.arrayMuestras = this.$store.getters["muestras/getAdded"];
            return this.$store.getters["muestras/getAdded"]
        },
       identity() {
            return this.$store.getters['admin/getIdentity'];
        },

      
    },
   
   methods:{
         ...mapActions('solserv', ['setHistoryOptionSrv']),
         ...mapActions('solserv', ['setAddedSrv']),
      
        ...mapActions('clientes', ['getInfoByIdCli']),
        ...mapActions('muestras', ['addItemMtr']),
        ...mapActions('muestras', ['getAllInfoMtr']),
        ...mapActions('profile', ['getAllInfoPrf']),
         ...mapActions('muestras', ['getInfoByIdMtr']),
         ...mapActions("parametros", ["getAllInfoPrm"]),
       
       
        includesItem(search, name) {
            search = search.trim()
            if (search == "") return true;
            name = name.toLowerCase();
            search = search.toLowerCase();
            if (search == "") return false;
            return name.includes(search);
        },

        eliminar: async function(id) {

            if(this.arrayMuestras.resultados.length > 1){

                let result = await this.$store.dispatch("muestras/deleteItemMtr", {option: "muestras", id: id})

                if(result.status=='error'){
                    this.status='error'
                    this.message= result.message
                    console.log('error')
                    this.delStatus()
                    
                }else {
                    let data ={
                        id:this.muestra.servicio.id_client,
                        option:'muestras/cliente'
                    }
                    let algodin = await this.getInfoByIdMtr(data)

                    for(let i=0; i<algodin.length; i++){
                        if(algodin[i].id == this.arrayMuestras.id){
                            this.arrayMuestras = algodin[i]
                        }
                    }
                }

            }
            this.waitdelete=true
            
        },
        
       saveResult: async function() {

           if(this.estudios.length>0){
                for(let i=0; i<this.estudios.length; i++){
                    let data = {
                        id_muestra: this.muestra.id,
                        id_param: this.estudios[i].id,
                        id_profile: this.estudios[i].id_profile
                    }

                    let result = await this.$store.dispatch("muestras/addItemMtr", {option: "muestras", item: data})

                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                        console.log('error')
                        this.delStatus()
                        
                    }else{ // success
                    
                        //this.arrayMuestras=[]
                        

                        let data ={
                            id:this.muestra.servicio.id_client,
                            option:'muestras/cliente'
                        }
                        let algodin = await this.getInfoByIdMtr(data)

                        for(let i=0; i<algodin.length; i++){
                            if(algodin[i].id == this.arrayMuestras.id){
                                this.arrayMuestras = algodin[i]
                            }
                        }

                        
                    } 

                }
               
           }

           
           this.getAllInfoMtr('muestras')
           this.showModal=false

       },

         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },


        delestudio(key){
            const filt = this.estudios.filter((item, index) => index != key)
            this.estudios = filt
        },
        eliminarUltimo: function(){
            let array=[];
            let items= this.estudios;
            let len = items.length;
            for(var i=0; i<items.length; i++){
                if(i!=(len-1)){
                    array.push(items[i])
                }
            }

            this.estudios = array

        },
        pushValue: function (value, id_profile){
            this.status='';
            this.message='';
            let values =[]
            values= this.estudios
            let encontrado ='no'
            for(var i=0; i<values.length; i++){
                if(values[i].id==value.id){
                    encontrado='si'
                }
            }
            if(encontrado=='no'){
                values.push({...value, id_profile})
                this.estudios = values 
            }else{
                this.status='error';
                this.message='Estudio ya agregado';
                this.delStatus()
            }

            this.viewParams=false //cerrar div cada que agregas un parametro.

            

        },
        pushValues: function (arr, id_profile){
            this.status='';
            this.message='';
            let values =[]
            values= this.estudios
            for (const item of arr) {
                let encontrado ='no'
                let value = item.param
                for(var i=0; i<values.length; i++){
                    if(values[i].id==value.id){
                        encontrado='si'
                    }
                }
                if(encontrado=='no'){
                    let model = {
                        ...value,
                        id_profile:id_profile
                    }
                    values.push(model)
                }
            }

            this.estudios = values
            this.viewParams=false

        },



        editarDatos: async function(){
            const data ={
                m_id	:	this.muestra.id,
                m_descrp	:	this.muestra.descrp,
                m_tipo	:	this.muestra.tipo,
                m_toma	:	this.muestra.toma,
              
                e_id	:	this.muestra.extra.id,
                e_apar	:	this.muestra.extra.apar,
                e_direc_toma	:	this.muestra.extra.direc_toma,
                e_obv	:	this.muestra.extra.obv
                
            }

             let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras/data', item: data});
                                        
            if(result.status=='error'){
                this.status='error'
                this.message= result.message
            }else{ // success
                this.wait()
            }

        },

        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
        back(){
           this.setHistoryOptionSrv('Muestras_Solicitud')
        },
      
             convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
        determinaciones(resultados){
            let str =''
            if(resultados ==''){
                return str
            }
            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pabr
                }else{
                    str = str + ',' + resultados[i].pabr
                }
            }

            return str
        },


       
   }

    
 
}
</script>

<style scoped>
  hr.hrinf{
    width: 57.969vw; 
    border: 0.5px solid #D3D3D3;
    margin-left: -1vw;
    margin-bottom: 2vw;
    margin-top: 1vw;
  }


    #muestrasClientes{
        margin-top: 5.288020833333333VW;
        margin-left: 0.9375VW ;
        }

        .info_cliente p{
            font-size: 0.677vw;
            padding-top: 0.148vw ;
            color: #868686;
        }

        p.client_name{
            font-size: 1.615vw;
            font-weight: 400;
            padding-top: 0vw;
        }

        p.t1p{
            font-weight: 300;
            font-style: normal;
            font-size: 1.615vw;
            color: #565656;
        }

        p.t2p{
            font-size: 1.042vw;
             font-weight: 300;
            font-style: normal;
            color: #565656;
        }

        p.tt2{
            margin-top: 2vw;
        }








        /** inputs */

   p.inp{
        border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
       font-weight: 700;
       height: 1.354vw;
       padding-left: 0.573vw;
   }
   input.inp{
        border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
       font-weight: 700;
       height: 1.354vw;
       padding-left: 0.573vw;
   }

   .mtp1{
    margin-top: 2vw;
   }

    p.i1{
        width: 10.993vw;
         font-weight: 700;
    }
    p.lbl1{
         font-weight: 400;
        width: 5.573vw;
        margin-right: 0.469vw;
        font-size: 0.729vw;
    }

    p.i2{
        width: 10.773vw;
        font-weight: 700;
    }
    p.lbl2{
         font-weight: 400;
         font-size: 0.729vw;
        width: 5.469vw;
        margin-right: 0.313vw;
        margin-left: 1.094vw;
    }

    p.i3{
        width: 12.388vw;
         font-weight: 700;
    }
    p.lbl3{
         font-size: 0.729vw;
         font-weight: 400;
        width: 6.458vw;
        margin-right: 0.417vw;
        margin-left: 0.885vw;
    }

      p.i4{
        width: 50.8vw;
         font-weight: 700;
    }
    p.lbl4{
         font-weight: 400;
         font-size: 0.729vw;
        width: 4.010vw;
        margin-right: 0.1vw;
    }

      p.lbl77{
         font-weight: 400;
         font-size: 0.729vw;
        width: 5.010vw;
        margin-right: 0.1vw;
    }
    p.i77{
        width: 49.8vw;
         font-weight: 700;
    }

    p.i5{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl5{
         font-weight: 400;
         font-size: 0.729vw;
        width:  6.667vw;
        margin-right: 0.417vw;
    }

     p.i6{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl6{
         font-weight: 400;
         font-size: 0.729vw;
        width:  5.052vw;
        margin-right: 2.031vw;
    }

    p.i7{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl7{
         font-weight: 400;
         font-size: 0.729vw;
        width:  7.02vw;
        margin-left: 0.679vw;
    }

      p.i8{
        width: 9.931vw;
        font-weight: 700;
        text-wrap-mode: nowrap;
        content-visibility: auto;
        overflow-x: scroll;
        height: fit-content;
    }
    p.lbl8{
         font-weight: 400;
         font-size: 0.729vw;
        width:  3.750vw;
        margin-right: 3.333vw;
    }
  



   .btnsFoo{
        cursor:pointer;
        width: 10.156vw;
        height: 2.292vw;
        border: 0px;
        margin-right: 0.072vw;
        font-size: 0.729vw;
        font-weight: 700;
        color:white;
        text-align: center;
        padding-top: .7vw;
        background: #007E94;
        margin-top: 2.7083333333333335VW;
    }
    .btf1{
         margin-left: 0.072vw;
    }
  


 



    /** TABLA */

    .tbl_muestracliente{
        width: 55VW; 
        margin-top: 1.5625VW;
    }

    .tbl_analistas{
        width: 45VW; 
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.625vw;
        padding-top: 0.66875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }

    .tbl_items{
        display: flex;
        align-items: center;
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: .9VW;
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
     
    }




    /* TABLA */

    .hdr1{
        width:18.229vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt1{
        width: 18.229vw;
         overflow: auto;
    }

    .hdr2{
        width:15.573vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt2{
        width: 15.573vw;
         overflow: auto;
    }

    .hdr3{
        width:12.969vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt3{
        width:12.969vw;
         overflow: auto;
    }

    .hdr4{
        width:8.229vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt4{
        width:8.229vw;
         overflow: auto;
    }
   .itt8{
        width:fit-content;
        overflow: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .itt8 img {
        width: 2vw;
        height: auto;
        position: absolute;
        margin-left: 0.5vw;
    }

    .hdr5{
        width:26.510vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt5{
        width:26.510vw;
         overflow: auto;
    }

      .hdr6{
        width:5.521vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt6{
        width:5.521vw;
         overflow: auto;
    }

       .hdr7{
        width:12.969vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt7{
        width:12.969vw;
         overflow: auto;
    }


    

     
.btnPlus {
    width: 100%;
    height: 2vw;
    background-color: var(--color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btnPlus img {
    width: 1.4vw;
    height: auto;
}

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }



    p.i8::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    p.i8::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    p.i8::-webkit-scrollbar-button:increment,p.i8::-webkit-scrollbar-button {
        display: none;
    } 

    p.i8::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    p.i8::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    p.i8::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }




.FModal {
    display: flex;
    background-color:rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0vw;
    right: 0vw;
    align-items: center;
    justify-content: center;
}


    .dvmuestras p{
        background: #009084;
        height: 2.2916666666666665VW;
        color: white;
        text-align: center;
    }    
    p.mt2{
        width: 16.947917VW;
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        margin-bottom: 0vw;
    
    }
    .estudios{
        background: #D3D3D3;
        width: 39.03125VW;
        height: 8.71875VW;
        overflow: auto;
        display: flex;
    }
    .est_item p{
        color: #797979;
        cursor: pointer;
        width: auto;
        margin-bottom: 0vw;
        padding-top: 0.3vw;
        padding-left: .5vw;
        margin-bottom: 0vw;
        font-weight: 400 !important;
    }
    .btnelimp{
        width: 21.2vw;
    }
    .buscador2, .buscador3 {
        display: flex;
        margin-left: 0vw;
    }
   .buscador2 input{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 11.250000000000002VW;
        height: 1.8192708333333332VW; 
        padding-left: 1.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
        border-right: 0vw;
    }.buscador2 input:target, .buscador3 input:target{
        background: var(--color-1);
    } .buscador3 input{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 14.43VW;
        height: 1.8192708333333332VW; 
        padding-left: 1.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
        border-right: 0vw;
    }
    .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
        border-left: 0vw;
    }.icon_buscar img{
        width: 0.9895833333333333VW;
        height: 0.9895833333333333VW;
        margin-top: 0.4411458333333334VW;
    }
    p.eliminarult2{
        color: #868686;
        font-style: italic;
        padding: .2vw .3vw;
        font-size: 0.6770833333333334VW;
    }
    p.cliheader{
        margin-top: 1vw;
        background: #009084;
        color: white;
        padding: .64vw;
        text-align: center;
        margin-bottom: 0vw;
    }
    .tablacli{
        overflow: auto;
        width: 14.7vw;
        height: 10vw;
        padding: .5vw;
        background-color: var(--color-5);
    }
    p.cliname{
        color: #868686;
        padding-bottom: .4vw;
        border-bottom:1px solid #D3D3D3;
        font-size: 0.6770833333333334VW;
        cursor: pointer;
    }
    
    .btnAdd>p {
        color: #FFFFFF;
        margin-left: 1vw;
        text-align: center;
        padding-top: .5vw;
        font-weight: 700;
        font-size: 1.05vw;
    }
    .btnAdd{
        width: 7.916666666666666VW;
        cursor: pointer;
        display: flex;
        background: #009084;
        margin-top: 0.5vw;
        justify-content: center;
        align-items: center;
    }
    .btnAdd img {
        width: 1.3vw;
        height: auto;
    }

.FB1 {
    margin-right: 1vw;
}

.btnPlus {
    width: 100%;
    height: 2vw;
    background-color: var(--color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btnPlus img {
    width: 1.4vw;
    height: auto;
}
.FB3 {
    cursor: pointer;
    margin-bottom: 34vw;
}
.FB3 img {
    width: 1vw;
    height: auto;
}















</style>
